import React from "react";
import Header from "../component/Header.js";
import Article1 from "../component/Article1.js";
import Keyword from "../component/Keyword.js";
import Card from "../component/Card.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import KeywordList from "../component/KeywordList.js";
import axios from "axios";

function Search() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [filteredContents, setFilteredContents] = useState();

  const searchText = searchParams.get("searchText"); // 검색기능 text 가져오기 (url 쿼리스트링)
  const onClickSearchBtn = () => {
    axios
      .get(`/api/getSearchDataList/${searchText}`)
      /* ,{params: { URLParam: searchText, },}) */
      .then((response) => {
        // API로부터 받은 데이터 처리
        // console.log(response.data);

        if (response.data) {
          const arrParsedData = response.data.map((item) => ({
            ...item,
            keyword: JSON.parse(item.keyword),
          }));
          setFilteredContents(arrParsedData);
        }
      })
      .catch((error) => {
        // 오류 처리
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    onClickSearchBtn();
  }, [searchText]);

  return (
    <>
      <Header />
      <Article1
        h2={
          <>
            <span style={{ color: "#558EFF" }}>
              {searchText ? searchText : "전체"}
            </span>{" "}
            검색 결과
          </>
        }
        dis={"displayNone"}
        inner={`inner1220`}
      />

      <div className="cardView">
        <div className="inner">
          {filteredContents && filteredContents.length > 0
            ? filteredContents.map((d) => (
                <Card
                  img={d.img}
                  keyword={[<KeywordList keyword={d.keyword} />]}
                  h3={d.h3}
                  p={d.p}
                  page={d.page}
                />
              ))
            : "검색 결과가 없습니다."}
        </div>
      </div>
    </>
  );
}

export default Search;
