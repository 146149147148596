import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function Keyword({ keyword, ac, clickMenu, onClickEvent, id }) {
  const navigate = useNavigate();
  // NEWS,BRAND,TRAVEL,ACTIVITY,HISTORY,HOW-TO,PEOPLE,SPACE

  //url 쿼리스트링으로 필터 걸기
  const [searchParams, setSearchParams] = useSearchParams();
  const setSortParams = () => {
    searchParams.set("sort", keyword);
    setSearchParams(searchParams);
  };

  return clickMenu === "Category" ? (
    <div
      className={`item ${keyword} ${ac ? "ac" : ""}`}
      onClick={setSortParams}
      key={id}
    >
      {keyword}
    </div>
  ) : clickMenu === "Main" ? (
    <div
      className={`item ${keyword} ${ac ? "ac" : ""}`}
      onClick={() =>
        navigate({
          pathname: "/Category",
          search: `?sort=${keyword}`,
        })
      }
      key={id}
    >
      {keyword}
    </div>
  ) : clickMenu === "Contents" ? (
    <div
      className={`item ${keyword} ${ac ? "ac" : ""}`}
      onClick={() =>
        navigate({
          pathname: "/Category",
          search: `?sort=${keyword}`,
        })
      }
      key={id}
      style={{ cursor: "pointer" }}
    >
      {keyword}
    </div>
  ) : (
    <div className={`item ${keyword} ${ac}`} key={id}>
      {keyword}
    </div>
  );
}

export default Keyword;
