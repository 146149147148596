import React from "react";
import Header from "../component/Header";
import Tab from "../component/Tab";
import Keyword from "../component/Keyword";
import { useNavigate } from "react-router-dom";
import { CATEGORY_LIST } from "../data/data";

function Main() {
  const navigate = useNavigate();

  return (
    <div className="mainBg">
      <Header />
      <div className="visual">
        <div className="keyVisualWrap">
          <div className="keyVisual">
            <div className="imgWrap">
              <img src="/img/main/img_mainTimes.png" alt="img" />
            </div>
            <div className="mainObj mainObj1">
              <img
                src="/img/main/img_mainObj1.png"
                alt="img"
                onClick={() => navigate("/page6")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mainObj mainObj2">
              <img
                src="/img/main/img_mainObj2.png"
                alt="img"
                onClick={() => navigate("/page5")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mainObj mainObj3">
              <img
                src="/img/main/img_mainObj3.png"
                alt="img"
                onClick={() => navigate("/page10")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mainObj mainObj4">
              <img
                src="/img/main/img_mainObj4.png"
                alt="img"
                onClick={() => navigate("/page4")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mainObj mainObj6">
              <img
                src="/img/main/img_mainObj6.png"
                alt="img"
                onClick={() => navigate("/page10")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="mainObj mainObj5">
              <img
                src="/img/main/img_mainObj5.png"
                alt="img"
                onClick={() => navigate("/page6")}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>
              진짜 제주다운 ‘서귀포의 얼굴’을 함께 나눠요.
              <br />
              105개 마을에서 노지 스타일을 즐기면서 발견하는, 리미티드 에디션형
              스토리.
            </p>
            <b>지금 시작합니다.</b>
          </div>
        </div>
      </div>

      <div className="linkMap">
        <div className="inner innerFull">
          <div className="tray">
            <div className="tit">어떤 이야기가 궁금한가요?</div>
            <Tab
              keyword={CATEGORY_LIST.map((category) => (
                <Keyword keyword={category.name} ac={``} clickMenu={"Main"} />
              ))}
            />
          </div>
        </div>
      </div>

      <div className="mainContents">
        <div className="inner innerFull">
          <div className="item" onClick={() => navigate("/page11")}>
            <div className="big">
              <div className="keyword">
                <Keyword keyword={"ACTIVITY"} />
                <Keyword keyword={"HISTORY"} />
                <Keyword keyword={"NEWS"} />
                <Keyword keyword={"BRAND"} />
              </div>
              <div className="tit">2023 문화도시 서귀포가 남긴 것 BEST 6</div>
              <p className="desc">
                사람은 이름을 남기고, 문화도시 서귀포는 괄목할 만한 노지문화
                사업을 남겼어요. 모르면 손해, 지금부터 함께 누려요. 새끼손가락
                끼고 약속.
              </p>
              <div className="imgWrap">
                <img src="/img/main/a.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="small" onClick={() => navigate("/page17")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"NEWS"} />
                </div>
                <div className="tit">
                  우리 이렇게 잘했어요, 2023 문화도시 서귀포
                </div>
                <div className="desc">
                  숨은 곳곳에 문화로 등불을 밝힌 문화도시 서귀포의 활약상, 자랑
                  좀 하고 갈게요.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/a1.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page3")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"HOW-TO"} />
                </div>
                <div className="tit">
                  다시 태어났어, 월별 제주어 럭키 리추얼
                </div>
                <div className="desc">
                  기억해요. 계절은 바뀌고 당신은 매일 새로운 날을 선물 받고
                  있음을.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/a2.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page18")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"TRAVEL"} />
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"HOW-TO"} />
                  <Keyword keyword={"BRAND"} />
                  <Keyword keyword={"SPACE"} />
                </div>
                <div className="tit">
                  띵동 캘린더, 서귀포의 3~4월은 이런 맛!
                </div>
                <div className="desc">
                  바로 지금 서귀포행을 서둘러야 할 결정적 이유.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/a3.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainContents reverse">
        <div className="inner innerFull">
          <div className="item" onClick={() => navigate("/page10")}>
            <div className="big">
              <div className="keyword">
                <Keyword keyword={"TRAVEL"} />
                <Keyword keyword={"HISTORY"} />
                <Keyword keyword={"PEOPLE"} />
                <Keyword keyword={"BRAND"} />
                <Keyword keyword={"SPACE"} />
              </div>
              <div className="tit">힙하게 변했어요, 공간의 재탄생</div>
              <p className="desc">
                이곳이 이렇게? 과거에 묻힐 뻔한 공간이 현대적인 미감을 덧입고
                오늘의 이야기를 만들어 가고 있어요. 이토록 힙한 재생이라니.
              </p>
              <div className="imgWrap">
                <img src="/img/main/b.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="small" onClick={() => navigate("/page14")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"PEOPLE"} />
                  <Keyword keyword={"BRAND"} />
                  <Keyword keyword={"SPACE"} />
                </div>
                <div className="tit">
                  대정에서 온 남자, 성산에서 온 여자 part1.서귀포에 취한 젊…
                </div>
                <div className="desc">
                  한라산을 가로질러 70.5km 거리, 서쪽 끝 대정에서 온 남자와 동쪽
                  끝 성산에서 온 여자가 만났을 때.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/b1.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page8")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"PEOPLE"} />
                  <Keyword keyword={"BRAND"} />
                  <Keyword keyword={"SPACE"} />
                </div>
                <div className="tit">그들이 서귀포를 택할 수밖에</div>
                <div className="desc">
                  영화와 드라마, 그림, 음악 등 황송하게도 예술의 소재로 간택된,
                  서귀포의 참 쓸모.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/b2.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page1")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"TRAVEL"} />
                  <Keyword keyword={"ACTIVITY"} />
                </div>
                <div className="tit">서귀포에서 내 집 찾기 대작전</div>
                <div className="desc">
                  세상에서 가장 편한 건 남의 집이 될 수 있어요. 용도별, 취향별
                  내 집 삼아 삼만리.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/b3.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainContents">
        <div className="inner innerFull">
          <div className="item" onClick={() => navigate("/page5")}>
            <div className="big">
              <div className="keyword">
                <Keyword keyword={"TRAVEL"} />
                <Keyword keyword={"ACTIVITY"} />
                <Keyword keyword={"HISTORY"} />
                <Keyword keyword={"HOW-TO"} />
                <Keyword keyword={"PEOPLE"} />
                <Keyword keyword={"BRAND"} />
                <Keyword keyword={"SPACE"} />
              </div>
              <div className="tit">한 템포 느리게, 보목마을</div>
              <p className="desc">
                보목천을 젖줄로 어미의 자궁처럼 따스하게 안착한 듯한 마을.
                여기에선 누구나 시간을 잊게 해요.
              </p>
              <div className="imgWrap">
                <img src="/img/main/c.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="small" onClick={() => navigate("/page7")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"TRAVEL"} />
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"HOW-TO"} />
                </div>
                <div className="tit">꿈틀꿈틀 동력, 하례1리ㆍ하례2리마을</div>
                <div className="desc">
                  동서로 흐르는 두 물길 사이의 생태가 영예로워요. 그에 주민들의
                  한 손과 한 걸음이 쌓아 올린 흔적을 기분 좋게 따라가죠.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/c1.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page6")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"TRAVEL"} />
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"HOW-TO"} />
                </div>
                <div className="tit">신비와 활기 사이, 신효ㆍ하효마을</div>
                <div className="desc">
                  효돈동이란 지붕 아래 내륙과 바다를 넘나들며 흐드러진 귤꽃의
                  향연. 달고 싱그럽고, 또 신비로워요.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/c2.jpg" alt="img" />
              </div>
            </div>
            <div className="small" onClick={() => navigate("/page12")}>
              <div className="textWrap">
                <div className="keyword">
                  <Keyword keyword={"TRAVEL"} />
                  <Keyword keyword={"ACTIVITY"} />
                  <Keyword keyword={"HISTORY"} />
                  <Keyword keyword={"HOW-TO"} />
                </div>
                <div className="tit">걸음마다 짭조름하게, 송산서귀마을</div>
                <div className="desc">
                  바다에 포복 자세를 한 채 서귀포의 짜디짠 역사가 저민 마을.
                  서귀포의 푸른 밤은 이곳에서 잠들지 않아요.
                </div>
              </div>
              <div className="imgWrap">
                <img src="/img/main/c3.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Main;
