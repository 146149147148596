import React from "react";
import Header from "../component/Header.js";
import Article1 from "../component/Article1.js";
import Keyword from "../component/Keyword.js";
import KeywordList from "../component/KeywordList.js";
import Card from "../component/Card.js";
import Tab from "../component/Tab.js";
import { CATEGORY_LIST, CATEGORY_CONTENTS_LIST } from "../data/data.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

function Category() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sort = searchParams.get("sort"); // sort 값을 가져온다. (url 쿼리스트링에서)

  const [filteredContents, setFilteredContents] = useState(
    CATEGORY_CONTENTS_LIST
  );
  const [subTitleObject, setSubTitleObject] = useState(CATEGORY_LIST[0]);

  useEffect(() => {
    const isAll = !sort || sort === "all";
    if (!CATEGORY_CONTENTS_LIST.length) return;
    if (isAll) {
      // sort의 값이 '전체'일 경우 전체 컨텐츠를 렌더링한다.
      setFilteredContents(CATEGORY_CONTENTS_LIST);
      return;
    }
    // sort의 값을 이용해 전체 컨텐츠에서 조건을 만족하는 새 컨텐츠로 구성된 배열을 만든다.
    const newContents = CATEGORY_CONTENTS_LIST.filter((c) =>
      c.keyword.includes(sort)
    );
    setFilteredContents(newContents);

    //소제목 세팅
    setSubTitleObject(CATEGORY_LIST.find((item) => item.parameter === sort));
  }, [sort, CATEGORY_CONTENTS_LIST]);

  return (
    <>
      <Header />
      <Tab
        inner={`inner1220`}
        keyword={[
          CATEGORY_LIST.map((category) => (
            <Keyword
              keyword={category.name}
              {...category}
              ac={category.name === sort}
              clickMenu={"Category"}
              id={category.id}
            />
          )),
        ]}
      />
      <div className="cateTit">
        <div className="inner1220">
          <h2>{subTitleObject ? subTitleObject.name : "ACTIVITY"}</h2>
          <h3>{subTitleObject ? subTitleObject.subTitle : "ACTIVITY"}</h3>
        </div>
      </div>

      <div className="cardView">
        <div className="inner">
          {filteredContents.map((d) => (
            <Card
              card={`card card2`}
              img={d.img}
              keyword={[<KeywordList keyword={d.keyword} />]}
              h3={d.h3}
              p={d.p}
              page={d.page}
              id={d.id}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Category;
