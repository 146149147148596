import React from "react";
import Keyword from "../component/Keyword";

function KeywordList({ keyword, clickMenu }) {
  // NEWS,BRAND,TRAVEL,ACTIVITY,HISTORY,HOW-TO,PEOPLE,SPACE

  // keyword.map((d) => alert(d));
  // console.log(keyword);

  return (
    <div className="keyword">
      {keyword.map((d) => (
        <Keyword keyword={d} clickMenu={clickMenu} id={d.id} />
      ))}
    </div>
  );
}

export default KeywordList;
