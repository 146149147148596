import React from "react";

export const CATEGORY_LIST = [
  {
    id: 1,
    parameter: "ACTIVITY",
    name: "ACTIVITY",
    subTitle: "서귀포에선 뭐든 가능할 듯한 활기가 활활",
  },
  {
    id: 2,
    parameter: "HOW-TO",
    name: "HOW-TO",
    subTitle: "서귀포를 가치 있게 누리는 남다른 비법",
  },
  {
    id: 3,
    parameter: "PEOPLE",
    name: "PEOPLE",
    subTitle: "오늘도 반짝이는 서귀포 시민의 한걸음",
  },
  {
    id: 4,
    parameter: "BRAND",
    name: "BRAND",
    subTitle: "다른 지역과는 차별화된 서귀포만의 단단함",
  },
  {
    id: 5,
    parameter: "HISTORY",
    name: "HISTORY",
    subTitle: "서귀포는 과거와 현재, 미래의 시간 중매자",
  },
  {
    id: 6,
    parameter: "NEWS",
    name: "NEWS",
    subTitle: "머리부터 발끝까지 문화도시 서귀포 생각",
  },
  /* {
    parameter: "MAP",
    name: "MAP",
    subTitle: "서귀포 문화도시 지식 충전하고 광명 찾자.",
  }, */
  {
    id: 7,
    parameter: "SPACE",
    name: "SPACE",
    subTitle: "서귀포를 구성하고 자극하는 우주적인 공간들",
  },
  {
    id: 8,
    parameter: "TRAVEL",
    name: "TRAVEL",
    subTitle: "제주행을 망설일 수 없는 찬란한 이유",
  },
];

export const CATEGORY_CONTENTS_LIST = [
  {
    id: 1,
    img: `/img/board/board1.jpg`,
    keyword: ["ACTIVITY", "HISTORY", "NEWS", "BRAND"],
    page: "/page11",
    place: "SEOGWIPO",
    h3: `2023 문화도시 서귀포가 남긴 것 BEST 6`,
    p: `사람은 이름을 남기고, 문화도시 서귀포는 괄목할 만한 노지문화 사업을 남겼어요. 모르면 손해, 지금부터 함께 누려요. 새끼손가락 끼고 약속.`,
  },
  {
    id: 2,
    img: `/img/place/seogwipo2.jpg`,
    keyword: ["HISTORY", "NEWS"],
    page: "/page17",
    place: "SEOGWIPO",
    h3: `우리 이렇게 잘했어요, 2023 문화도시 서귀포`,
    p: `숨은 곳곳에 문화로 등불을 밝힌 문화도시 서귀포의 활약상, 자랑 좀 하고 갈게요.`,
  },
  {
    id: 3,
    img: `/img/board/board3.jpg`,
    keyword: ["HOW-TO"],
    place: "SEOGWIPO",
    page: "/page3",
    h3: `다시 태어났어, 월별 제주어 럭키 리추얼`,
    p: `기억해요. 계절은 바뀌고 당신은 매일 새로운 날을 선물 받고 있음을. `,
  },
  {
    id: 4,
    img: `/img/board/calendar_01.jpg`,
    keyword: ["TRAVEL", "ACTIVITY", "HISTORY", "HOW-TO", "BRAND", "SPACE"],
    page: "/page18",
    place: "SEOGWIPO",
    h3: `띵동! 서귀포 3~4월 캘린더`,
    p: `바로 지금 서귀포행을 서둘러야 할 결정적 이유`,
  },
  {
    id: 5,
    img: `/img/board/board5.jpg`,
    keyword: ["ACTIVITY", "HISTORY", "PEOPLE", "BRAND", "SPACE"],
    page: "/page14",
    place: "NOJI",
    h3: `대정에서 온 남자, 성산에서 온 여자 part1.서귀포에 취한 젊음의 기록`,
    p: `한라산을 가로질러 70.5km 거리, 서쪽 끝 대정에서 온 남자와 동쪽 끝 성산에서 온 여자가 만났을 때.`,
  },
  {
    id: 6,
    img: `/img/board/board6.jpg`,
    keyword: ["TRAVEL", "HISTORY", "PEOPLE", "BRAND", "SPACE"],
    page: "/page15",
    place: "NOJI",
    h3: `대정에서 온 남자, 성산에서 온 여자 part2.우리 마을로 들어와, ‘드루와’!`,
    p: `우리 마을의 매력을 뽐내다 보면 끝이 없죠. 그나저나 서로 언제쯤 다시 만날 수 있으려나…`,
  },
  {
    id: 7,
    img: `/img/board/board7.jpg`,
    keyword: ["TRAVEL", "PEOPLE", "BRAND", "SPACE"],
    page: "/page8",
    place: "NOJI",
    h3: `그들이 서귀포를 택할 수밖에`,
    p: `영화와 드라마, 그림, 음악 등 황송하게도 예술의 소재로 간택된, 서귀포의 참 쓸모. `,
  },
  {
    id: 8,
    img: `/img/board/board8.jpg`,
    keyword: ["HISTORY", "BRAND", "PEOPLE"],
    page: "/page2",
    place: "NOJI",
    h3: `네가 ‘찐’ 노지 브랜드를 알아?`,
    p: `노지 브랜드가 뭐냐고 물으신다면. 제주 역사의 끈을 이어 자생적으로 성장해 온 브랜드라 비장하게 말합니다. 일단, 박수 백 번 시작! `,
  },
  {
    id: 9,
    img: `/img/board/noji5.jpg`,
    keyword: ["ACTIVITY", "TRAVEL", "PEOPLE"],
    page: "/page4",
    place: "NOJI",
    h3: `체험은 역시 ‘현장빨’`,
    p: `블루라이트 압박의 비대면 수업보다 오감을 자극하는 현장 체험이 자양강장제. 서귀포에서 아니면 만나기 어려움. `,
  },
  {
    id: 10,
    img: `/img/board/board10.jpg`,
    keyword: ["TRAVEL", "ACTIVITY"],
    page: "/page1",
    place: "NOJI",
    h3: `서귀포에서 내 집 찾기 대작전`,
    p: `세상에서 가장 편한 건 남의 집이 될 수 있어요. 용도별, 취향별 내 집 삼아 삼만리. `,
  },
  {
    id: 11,
    img: `/img/board/board11.jpg`,
    keyword: ["TRAVEL", "ACTIVITY", "HOW-TO", "PEOPLE", "BRAND", "SPACE"],
    page: "/page13",
    place: "NOJI",
    h3: `지구는 누가 지키나? 내가!`,
    p: `“Idea is nothing, doing is everything!” 생각은 그만- 우리집인 지구를 청소하고 가꾸는 행동을 내가 먼저 시작해요. `,
  },
  {
    id: 12,
    img: `/img/board/board12.jpg`,
    keyword: ["TRAVEL", "HISTORY", "PEOPLE", "BRAND", "SPACE"],
    page: "/page10",
    place: "NOJI",
    h3: `힙하게 변했어요, 공간의 재탄생`,
    p: `이곳이 이렇게? 과거에 묻힐 뻔한 공간이 현대적인 미감을 덧입고 오늘의 이야기를 만들어 가고 있어요. 이토록 힙한 재생이라니. `,
  },
  {
    id: 13,
    img: `/img/place/lounge1.jpg`,
    keyword: ["TRAVEL", "PEOPLE", "HISTORY", "HOW-TO"],
    page: "/page9",
    place: "105",
    h3: `이것이야말로 노지 매너!`,
    p: `여행 중이죠? 서로에 대한 배려가 싹틀 때, 걸음마다 팝콘처럼 터지는 기쁨이 있어요.`,
  },
  {
    id: 14,
    img: `/img/board/board14.jpg`,
    keyword: [
      "TRAVEL",
      "ACTIVITY",
      "HISTORY",
      "HOW-TO",
      "PEOPLE",
      "BRAND",
      "SPACE",
    ],
    page: "/page12",
    place: "105",
    h3: `걸음마다 짭조름하게, 송산서귀마을`,
    p: `바다에 포복 자세를 한 채 서귀포의 짜디짠 역사가 저민 마을. 서귀포의 푸른 밤은 이곳에서 잠들지 않아요. `,
  },
  {
    id: 15,
    img: `/img/board/board15.jpg`,
    keyword: [
      "TRAVEL",
      "ACTIVITY",
      "HISTORY",
      "HOW-TO",
      "PEOPLE",
      "BRAND",
      "SPACE",
    ],
    page: "/page5",
    place: "105",
    h3: `한 템포 느리게, 보목마을`,
    p: `보목천을 젖줄로 어미의 자궁처럼 따스하게 안착한 듯한 마을. 여기에선 누구나 시간을 잊게 해요. `,
  },
  {
    id: 16,
    img: `/img/board/board16.jpg`,
    keyword: [
      "TRAVEL",
      "ACTIVITY",
      "HISTORY",
      "HOW-TO",
      "PEOPLE",
      "BRAND",
      "SPACE",
    ],
    page: "/page6",
    place: "105",
    h3: `신비와 활기 사이, 신효ㆍ하효마을`,
    p: `효돈동이란 지붕 아래 내륙과 바다를 넘나들며 흐드러진 귤꽃의 향연. 달고 싱그럽고, 또 신비로워요. `,
  },
  {
    id: 17,
    img: `/img/board/board17.jpg`,
    keyword: [
      "TRAVEL",
      "ACTIVITY",
      "HISTORY",
      "HOW-TO",
      "PEOPLE",
      "BRAND",
      "SPACE",
    ],
    page: "/page7",
    place: "105",
    h3: `꿈틀꿈틀 동력, 하례1리ㆍ하례2리마을`,
    p: `동서로 흐르는 두 물길 사이의 생태가 영예로워요. 그에 주민들의 한 손과 한 걸음이 쌓아 올린 흔적을 기분 좋게 따라가죠. `,
  },
  {
    id: 18,
    img: `/img/board/board18.jpg`,
    keyword: [
      "TRAVEL",
      "ACTIVITY",
      "HISTORY",
      "HOW-TO",
      "PEOPLE",
      "BRAND",
      "SPACE",
    ],
    page: "/page16",
    place: "105",
    h3: `흐려도 분위기, 신례1리ㆍ신례2리마을`,
    p: `시선을 던지는 곳마다 자연의 질감과 표정이 다채로워요. 예를 아는 주민만큼이나 격 있는 풍경이 기다리죠.`,
  },
];
