import React from "react";
import Header from "../component/Header";
import Article1 from "../component/Article1";
import Contents3 from "../component/Contents3";
import Recommend from "../component/Recommend";
import Keyword from "../component/Keyword";
import RecoCard from "../component/RecoCard";
import { CATEGORY_CONTENTS_LIST } from "../data/data";
import KeywordList from "./../component/KeywordList";

function Page9() {
  return (
    <div>
      <Header />
      <Article1
        keyword={[
          <Keyword keyword={"TRAVEL"} clickMenu={"Contents"} />,
          <Keyword keyword={"HISTORY"} clickMenu={"Contents"} />,
          <Keyword keyword={"HOW-TO"} clickMenu={"Contents"} />,
          <Keyword keyword={"PEOPLE"} clickMenu={"Contents"} />,
        ]}
        h2={"이것이야말로 노지 매너!"}
        p={
          <>
            여행 중이죠? 서로에 대한 배려가 싹틀 때, 걸음마다 팝콘처럼 터지는
            기쁨이 있어요.
          </>
        }
        tit={"노지 매너의 이유 있는 이유"}
        item={
          <>
            <div className="item">
              <p>
                제주엔 숭고한 역사 가운데 특화된 생활방식이 있어요. 이를
                존중해야 당신이 사랑한 제주는 그 모습대로, 또는 그 이상으로
                지속될 수 있죠.{" "}
              </p>
            </div>
            <div className="item">
              <p>
                매너라는 건 내가 바라는 상대의 태도이기도 하죠. 누구에게나
                대접받고 싶은 마음, 내가 먼저 실천해 봐요.{" "}
              </p>
            </div>
            <div className="item">
              <p>
                이 길을 아름답게 여행한 당신이란 한 사람은 또 다른 사람의 여행을
                가능하게 한 선구자예요. 우린 결국 서로 연결된 사이라는 진리를
                진하게 느껴 보세요.{" "}
              </p>
            </div>
          </>
        }
      />

      <Contents3
        num={`01`}
        conTit={`“안녕하세요!”는 손해 없는 친절 `}
        img={`/img/basic/img_35.jpg`}
        desc={`올레 코스를 통해 혹은 그저 정처 없이 노지 마을 구석구석 여행하는 이들이 늘고 있어요. 마을에서 어르신을 만났을 때 어색하게 시선을 돌리는 대신 “안녕하세요!” 한 마디 어때요? 손해 볼 게 하나도 없죠. 당찬 목소리에서 내 마음에도 활기가 넘쳐요. 인심 좋은 어르신이 밭에서 무라도 쑥 하나 뽑아줄지 모르겠어요. 아니면 상추, 당근, 브로콜리, 콜라비…. 노지 귤은 두말하면 잔소리고요. `}
      />

      <Contents3
        num={`02`}
        conTit={`렌터카도, 마음도 스크래치 나기 전에 `}
        img={`/img/basic/img_36.jpg`}
        desc={`제주의 대표적인 얼굴 중 하나인 돌담이죠. 고층 빌딩에 가려진 시야 대신 키 낮은 돌담으로 펼쳐진 제주를 만나는 건 특별한 경험이에요. 간혹 좁은 마을 안쪽에 있는 행선지에 차로 가다가 이도 저도 못하는 모습을 보곤 하는데요. 돌담은 오랜 시간 그 자리에서 짓궂은 기후를 견디며 자리잡은 땅과 같아요. 무리한 마을로의 진입은 소중한 렌터카와 우리의 마음에 되돌릴 수 없는 스크래치를 낼 수 있죠. 스스로는 믿되 운전 실력은 너무 믿지 말아요, 우리. `}
      />

      <Contents3
        num={`03`}
        conTit={`야옹아, 널 촬영해도 되겠니? `}
        img={`/img/basic/img_37.jpg`}
        desc={`제주엔 내 것으로 해도 무방비한 자연유산이 넘쳐요. 저 바다, 저 하늘 모두 지구 세입자인 우리가 받는 선물이죠. 다만 촬영이 금지되거나 사전 허가가 필요한 마을 주민의 집 안쪽, 해녀처럼 초상권 있는 사람에겐 셔터 누르기를 잠시 멈춰 주세요. 사진으로 기록하고 싶은 열정(?)이 끓어오른다면 미리 “너무 예뻐서 사진에 담고 싶어서 그러는데요.”란 다정한 허락을 구하는 게 기본이겠죠. 지나가는 냥이야, 너는 그냥 찍어도 괜찮지(라고 해줘)? `}
      />

      <Contents3
        num={`04`}
        conTit={`카레이서의 본능은 지정된 구간에서 `}
        img={`/img/basic/img_38.jpg`}
        desc={`해안도로나 산간도로 외에도 마을 안길을 드라이브할 때, 제주만의 야무진 매력에 빠져들고 마는데요. 어린이보호구역이 아니더라도 종종 시속 30km/h라는 표지판을 마주한 적 있을 거예요. 이는 노인보호구역, 즉 실버존의 제한 속도로 천천히 길을 건너는 어르신을 배려한 우리의 약속이죠. 부모님 그리고 (존중받고 싶은) 미래의 우리를 떠올리며, 이 김에 마을 풍경을 깊이 받아들이며 느리게 가요. `}
      />

      <Contents3
        num={`05`}
        conTit={`자연에도 집이 있다`}
        img={`/img/basic/img_39.jpg`}
        desc={`해변이나 오름에서 발견한 예쁜 돌, 한 번쯤 소유하고 싶다는 생각을 한 적 있죠? 공통으로 생각하는 이것이 그대로 실현된다면 제주는 지금의 모습이 아닐 거예요. 캠핑족이 늘어나면서 돌을 끌어다가 사용하고 내팽개치는 불편한 제보가 들리고 있어요. 흙이나 모래, 야생화 역시 그 자리에 있기에 가장 아름다운 자연이죠. 모든 생명에는 이유가 있듯 그들을 제자리에, 그들이 자생해서 사는 그곳에 남겨두어요. 그저 경이롭게 찬양해 주세요. `}
      />

      <Contents3
        num={`06`}
        conTit={`여기에 내가 주정차한다면`}
        img={`/img/basic/img_40.jpg`}
        desc={`주차하거나 정차하기 전, “괜찮나?”란 시뮬레이션 시간을 가져 봐요. 혹 사고의 위험이 있는 사거리 모퉁이는 아닌가요? 대문이나 차고지 앞, 혹은 장애인 전용이나 전기차 충전 구역은 아닌지요? 해안가 앞 자전거 도로도 주차장일 리 없어요. 정차라도 비상 깜빡이가 절대적인 면죄부일 순 없죠. 조금 멀어도 공영주차장이 진리! 급하고 편하고 싶은 마음은 화장실 갈 때만 써봐요. 끄응. `}
      />

      <Contents3
        num={`07`}
        conTit={`여기 밭에 주인 있어요`}
        img={`/img/basic/img_41.jpg`}
        desc={`밭에서 농작물을 캐거나 딴 후 버려진 농작물을 ‘파치’라고 해요. 못생기거나 흠이 있는 등 상품성이 낮은 작물을 이르죠. 어쩌다가 파치는 누구나 주워도 된다는 소문이 돌아 채소밭이나 귤밭에 무단 침입해 마을 주민의 언성을 높이는 경우가 있어요. ‘저대로 버려질 바에 내가 줍는 게 낫지 않나?’란 생각은 엄연히 밭 주인이 판단할 몫이죠. 혹 주인을 만난다면 ‘줍줍’을 떳떳하게 물어봐요. 상냥하게 웃으면서요. `}
      />

      <Contents3
        num={`08`}
        conTit={`쓰레기로부터 세상 구하기 작전`}
        img={`/img/basic/img_42.jpg`}
        desc={
          <>
            전 지구적인 쓰레기 문제에서 제주도 예외일 순 없겠죠. 우리가 누군데,
            양심을 버릴 순 없잖아요? 서귀포에는 오후 3시 이후부터 이용 가능한
            클린하우스나 오전 6시부터 오후 10시까지 운영하는 재활용도움센터에서
            쓰레기를 담아내고 있어요. 차 안에 종량제 봉투 하나를 마련하고,
            좋아하는 길에 보이는 쓰레기를 줍는다면! 세상은 우리가 구할 수
            있어요. ‘나 하나쯤이야’ 대신 ‘나부터’로 방향 전환해 봐요, 휙휙.
          </>
        }
      />

      <Contents3
        num={`09`}
        conTit={`제주도의 푸른 밤은 조용해야 온다`}
        img={`/img/basic/img_43.jpg`}
        desc={`제주를 여러 번 찾은 이들 사이에선 바닷가나 번화가보다 좀 더 마을 안쪽의 숙소에 관심을 두기 시작하는데요. 어느새 달님, 별님이 찾아와 들뜬 기분에 고성을 지르다가는 민박집 주인이 마을 주민의 원성을 사게 돼요. 매일의 삶을 이어가는 주거지란 점을 기억하고, 목소리에 적정 데시벨을 유지해 봐요. 다음날 목이 잠기는 일도, 에너지가 방전될 위험도 없죠. 제주도의 진정한 푸른 밤은 잠잠함에서 찾아옵니다.`}
      />

      <Recommend
        tit={
          <>
            이 콘텐츠를 좋아하면,
            <br />
            이것도 좋아해요.
          </>
        }
        recoCard={[
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[16].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[16].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[16].h3}
            desc={CATEGORY_CONTENTS_LIST[16].p}
            page={CATEGORY_CONTENTS_LIST[16].page}
          />,
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[13].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[13].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[13].h3}
            desc={CATEGORY_CONTENTS_LIST[13].p}
            page={CATEGORY_CONTENTS_LIST[13].page}
          />,
        ]}
      />
    </div>
  );
}

export default Page9;
