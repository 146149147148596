import React from "react";
import { useNavigate } from "react-router-dom";

function Card({ card = "card", keyword, h3, p, img, page, id }) {
  const navigate = useNavigate();
  return (
    <div
      className={card}
      onClick={page ? () => navigate(page) : null}
      style={page ? { cursor: "pointer" } : {}}
      key={id}
    >
      <div className="imgWrap">
        <img src={img} alt="img" />
        <div className="badgeWrap">{/* <i className='badge kiz'></i> */}</div>
      </div>
      <div className="keywordWrap">{keyword}</div>
      <h3>{h3}</h3>
      <p>{p}</p>
    </div>
  );
}
export default Card;
