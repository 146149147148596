import React from "react";
import { useNavigate } from "react-router-dom";

function RecoCard({ img, keyword, conTit, desc, page }) {
  const navigate = useNavigate();
  return (
    <div
      className="card"
      onClick={() => navigate(page)}
      style={{ cursor: "pointer" }}
    >
      <div className="imgWrap">
        <img src={img} alt="img" />
      </div>
      <div className="rightCon">
        <div className="keyword">{keyword}</div>
        <div>
          <div className="conTit">{conTit}</div>
          <div className="desc">{desc}</div>
        </div>
      </div>
    </div>
  );
}

export default RecoCard;
