import React from "react";

function Contents({
  color,
  img1,
  img2,
  caption,
  caption1,
  caption2,
  dog,
  kiz,
  obs,
  contTit,
  location,
  desc,
  checkList,
  stayBtn,
  url,
}) {
  return (
    <>
      <div className="contents">
        <div className="inner">
          <div className="imgWrap">
            <img src={img1} alt="" />
            <div className="caption">
              {caption1 ? caption1 : caption ? caption : ""}
              {/* 기존 caption 하나로 사진 2개에 같은 캡션 사용, caption1/2로 prop 넘겨서 각각 사용 가능하도록 수정 */}
            </div>
          </div>
          <div className="imgWrap">
            <img src={img2} alt="" />
            <div className="caption">
              {caption2 ? caption2 : caption ? caption : ""}
            </div>
          </div>
          <div className="itemWrap">
            <div className={`tag ${dog}`}>반려 (대형견X)</div>
            <div className={`tag ${kiz}`}>키즈</div>
            <div className={`tag ${obs}`}>장애</div>
          </div>
          <div className="conTit">{contTit}</div>
          <div className="location">{location}</div>
          <p className="desc">{desc}</p>
          <div className="checkList">
            <div className="tit">Check list</div>
            <div className="listWrap">{checkList}</div>
          </div>
          <div className={`stayLink ${color}`}>
            <a href={url} target="_blank">
              <div className="stayBtn">{stayBtn}</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contents;
