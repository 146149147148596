import React from 'react';

function CheckList({props}) {
    return (
        <>
            <div className="list">{props}</div>
        </>
    ) 
}

export default CheckList;