import React from "react";
function ConTit({ text, key123 }) {
  return (
    <div class="conTit">
      {key123 ? key123 : null}
      {text}
    </div>
  );
}
export default ConTit;
