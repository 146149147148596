import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();
  const loc = useLocation();
  const [hashValue, setHashValue] = useState("");

  useEffect(() => {
    // console.log(loc.hash);
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
  }, [pathname, hash]);

  return null;
}
