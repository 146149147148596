import React from "react";
import Header from "../component/Header";
import Article1 from "../component/Article1";
import Recommend from "../component/Recommend";
import Keyword from "../component/Keyword";
import RecoCard from "../component/RecoCard";
import ListGroup from "../component/ListGroup";
import ImgSlider from "../component/ImgSlider";
import { CATEGORY_LIST, CATEGORY_CONTENTS_LIST } from "../data/data";
import Card from "../component/Card";
import KeywordList from "../component/KeywordList";

function Page5() {
  const style1 = {
    marginTop: "64px",
  };
  return (
    <div>
      <Header />
      <Article1
        keyword={[
          [
            "TRAVEL",
            "ACTIVITY",
            "HISTORY",
            "HOW-TO",
            "PEOPLE",
            "BRAND",
            "SPACE",
          ].map((category) => (
            <Keyword keyword={category} clickMenu={"Contents"} />
          )),
        ]}
        h2={`한 템포 느리게, 보목마을`}
        p={`보목천을 젖줄로 어미의 자궁처럼 따스하게 안착한 듯한 마을. 여기에선 누구나 시간을 잊게 해요. `}
        img={
          <>
            <div className="keyImg" style={style1}>
              <img src="/img/contents/bomok/bomok_map1.jpg" alt="img" />
            </div>
          </>
        }
        dis={"displayNone"}
      />

      <div className="listGroupWrap">
        <div className="inner">
          <ListGroup
            num={`1`}
            tit={`소천지`}
            desc={`백두산 천지를 축소해 놓은 듯해 붙여진 이름. 소천지에 투영된 한라산을 보며, 미니형 탑 위 돌 하나에 소원을 얹는 시간. `}
          />
          <ListGroup
            num={`2`}
            tit={`루씨쏜 아뜰리에`}
            desc={`제주 노지문화를 우아하게 민화로 발현한 갤러리 겸 스튜디오, 카페로 무장한 복합 문화 공간. 민화에 예술 투혼을 한 번?`}
          />
          <ListGroup
            num={`3`}
            tit={`백주산보`}
            desc={`마을 내 공간도 예술, 커피 맛도 예술인 북 카페. 사진과 영화 관련 예술 서적이 가득, 시간이 사라지는 여유는 한 보따리. `}
          />
          <ListGroup
            num={`4`}
            tit={`구두미포구`}
            desc={`섶섬을 가장 근시안에서 바라보는 명당. 수영과 스노클링, 낚시의 성지. 포구 맞은편 ‘오뎅 맛집’으로 소문난 푸드트럭 이용 가능.`}
          />
          <ListGroup
            num={`5`}
            tit={`보목천(정수내/장술내) `}
            desc={`마을의 중심부에서 동서로 나뉘는 경계선. 동쪽 동네는 어업, 서쪽 동네는 농업 위주. 자연에 기반한 생활상 탐미 포인트. `}
          />
          <ListGroup
            num={`6`}
            tit={`보목포구`}
            desc={`만조 시간대의 화창한 날, <보목해녀의집> 주차장 부근이 윤슬 포토존으로 각광. 돌담 사이의 바다로 가는 계단은 반짝반짝 천국행. 제주도 등록문화재로 지정된 민간 등대 ‘도대불’ 찾아보기.`}
          />
          <ListGroup
            num={`7`}
            tit={`제지기오름(재지기오름/절오름) `}
            desc={`나무 사이에 걸린 보목포구, 섶섬의 풍경화를 내어주는 해발 92m의 전망대. 왕복 약 20~30분 정도의 난이도 낮은 오름으로, 아이와의 산책길로 도전 가능.`}
          />
          <ListGroup
            num={`8`}
            tit={`섶섬`}
            desc={`포구 앞바다 1km 지점. 잘 깎인 바위가 불시착한 듯한 이 무인도엔 450종의 난대림이 귀한 생을 이어가는 중. 천연기념물 제18호.`}
          />
          <ListGroup
            num={`9`}
            tit={`제주 올레 6코스 `}
            desc={`쇠소깍에서 제주 올레 여행자 센터로 이어지는 11km의 올레 관통. 제지기오름에서 보목/구두미 포구 해안 길을 관통해 소천지까지 산책 추천.`}
          />
        </div>
      </div>

      <div className="reactSlick">
        <div className="inner">
          <ImgSlider
            obj={[
              <div>
                <img src="/img/contents/bomok/bomok_a1.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_a2.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_a3.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_a4.jpg" alt="slideImg" />
              </div>,
            ]}
          />
        </div>
      </div>

      <div className="postImg">
        <div className="inner">
          <img src="/img/contents/bomok/bomok_summary.jpg" alt="img" />
        </div>
      </div>

      <div className="contents3">
        <div className="inner">
          <h3 style={{ color: "#3198FF" }}>보목마을의 가치 하나둘셋</h3>
          <h4>짭조름한 제주 올레 6코스 반타작</h4>
          <p>
            올레를 거니는 마음은 정복보다 향유에 가까운데요. 총 11km에 달하는
            제주 올레 6코스 중 제지기오름부터 해안 길을 따라 소천지까지,
            보목마을을 관통하는 약 4.5km를 유유자적 걸어보는 건 어떨까요? 오름
            오르기를 포함한다면 1만 걸음에 가까우니 시도해 볼 용기도, 해냈다는
            뿌듯함도 함께할 수 있죠. 마지막 행선지인 소천지에선 좌청룡으로
            섶섬을, 우청룡으로 한라산을 받아들이며 마무리해 보세요. 삐죽삐죽한
            돌이 둘리어 물 정원을 이루는 이곳을 빠져나오며 돌탑 위로 작은 돌
            하나, 마음의 평화도 빌어 봐요. 오늘도 감사.
          </p>
        </div>
      </div>

      <div className="reactSlick">
        <div className="inner">
          <ImgSlider
            obj={[
              <div>
                <img src="/img/contents/bomok/bomok_b1.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_b2.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_b3.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_b4.jpg" alt="slideImg" />
              </div>,
            ]}
          />
        </div>
      </div>

      <div className="contents3">
        <div className="inner">
          <h4>{`<백주산보>에서의 햇살 충전`}</h4>
          <p>
            {`바다를 등지고 마을 안쪽으로 걸음을 떼보죠. 시시때때로 참견하는 햇살과 카페지기인 고진숙 대표의 따스함이 아로마 향을 이루는 <백주산보>입니다. 2019년부터 대표의 디테일이 책 사이에도 스민 이곳은 고요의 정점을 찍어요. 그래서인지 계속 있고 싶고, 빛이 주는 향유가 소중해지면서 나란 존재를 관찰하게 하죠. 작은 못 사이로 두 집 살림하는 공간은 평소엔 북 카페, 원한다면 콘서트장이나 갤러리로 변신하는 오픈형이에요. 토요일 오후 7시마다 영화를 보는 ‘바나나토크’가 있으니, 시간대가 맞는다면 슬쩍 들러 보세요. 어쩌다가 시간이 이리되었죠? 오늘의 낙조를 감상할 시간입니다.`}
          </p>
          <a
            href="http://instagram.com/baekjusanpo/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="insta">baekjusanpo</div>
          </a>
        </div>
      </div>

      <div className="reactSlick">
        <div className="inner">
          <ImgSlider
            obj={[
              <div>
                <img src="/img/contents/bomok/bomok_c1.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_c2.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_c3.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_c4.jpg" alt="slideImg" />
              </div>,
            ]}
          />
        </div>
      </div>

      <div className="contents3">
        <div className="inner">
          <h4>{`섶섬과 문섬의 마리아주, 오늘의 낙조`}</h4>
          <p>
            {`보목마을이 낀 해안도로는 섶섬이 내내 시야에 걸린 채 펼침면인 바다 위를 달리는 기분이 들어요. 해 질 무렵, 해안가에서의 시간을 충분히 허락해 주세요. 그 중 <섶섬한그릇> 앞에선 섶섬과 문섬 뒤로 넘어가는 다소 입체적인 낙조를 마주할 수 있어요. 간혹 하늘과 바다가 쪼개진 세상 사이로 잦은 섬광을 비출 때도 있고, 몽글몽글 구름 사이로 울긋불긋 노을색 붓 터치를 할 때도 있죠. 생경하고 황홀해요. 그리고 그날 낙조는 당신이 처음 만났어요. 처음.`}
          </p>
        </div>
      </div>

      <div className="reactSlick">
        <div className="inner">
          <ImgSlider
            obj={[
              <div>
                <img src="/img/contents/bomok/bomok_d1.jpg" alt="slideImg" />
              </div>,
              <div>
                <img src="/img/contents/bomok/bomok_d2.jpg" alt="slideImg" />
              </div>,
            ]}
          />
        </div>
      </div>

      <Recommend
        tit={
          <>
            이 콘텐츠를 좋아하면,
            <br />
            이것도 좋아해요.
          </>
        }
        recoCard={[
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[13].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[13].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[13].h3}
            desc={CATEGORY_CONTENTS_LIST[13].p}
            page={CATEGORY_CONTENTS_LIST[13].page}
          />,
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[12].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[12].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[12].h3}
            desc={CATEGORY_CONTENTS_LIST[12].p}
            page={CATEGORY_CONTENTS_LIST[12].page}
          />,
        ]}
      />
      {/* <div className="cardView">
        <div className="inner">
          <Card
            card={`card card2`}
            img={`/img/board/board9.jpg`}
            keyword={[<KeywordList keyword={["HISTORY", "BRAND", "PEOPLE"]} />]}
            h3={`체험은 역시 ‘현장빨’`}
            p={`블루라이트 압박의 비대면 수업보다 오감을 자극하는 현장 체험이 자양강장제. 서귀포에서 아니면 만나기 어려움. `}
            page={`/page4`}
            id={9}
          />
        </div>
      </div> */}
    </div>
  );
}

export default Page5;
