import React from "react";
import Article1 from "../component/Article1";
import Header from "../component/Header";
import Keyword from "../component/Keyword";
import RecoCard from "../component/RecoCard";
import Recommend from "../component/Recommend";
import { CATEGORY_CONTENTS_LIST } from "../data/data.js";
import KeywordList from "./../component/KeywordList";

function Page3() {
  /* const imgMargin = {
    margin: "0",
    marginTop: "-48px",
  }; */

  return (
    <>
      <Header />
      <Article1
        keyword={[<Keyword keyword={"HOW-TO"} clickMenu={"Contents"} />]}
        h2={"다시 태어났어, 월별 제주어 럭키 리추얼"}
        p={"기억해요. 계절은 바뀌고 당신은 매일 새로운 날을 선물 받고 있음을."}
        img={
          <>
            <div className="keyImg">
              <img src="/img/basic/img_14.jpg" alt="img" />
            </div>
          </>
        }
        tit={"제주어 일력과의 리추얼법"}
        item={
          <>
            <div className="item">
              <p>
                아무리 바빠도 내 몸과 마음은 지키는 시대. 제주어 일력과 함께
                ‘내가 풍요로워지는 의식’인 리추얼ritual을 해보는 건 어떨까요?
                매월, 3개씩, 행운도 건너와요.{" "}
              </p>
            </div>
            <div className="item">
              <p>
                좋은 기분이 마음에 걸어오는 제주어 문장을 소리내어 읽어 보세요.
                잊지 말아요. 오늘은 당신에게 주어진 새로운 하루라는 걸.
              </p>
            </div>
            <div className="item">
              <p>{`<2024 날마다 제주어> 일력에는 서귀포 시민의 그림과 제주어 문장이 찰떡궁합을 이루고 있어요. 그 소중한 마음과 정성을 떠올리며 함께 해보아요.`}</p>
            </div>
          </>
        }
      />

      <div className="inner380" style={{ marginBottom: "80px" }}>
        <div className="inner">
          <img
            className="fullImg"
            src="/img/basic/jejuLangCon1.jpg"
            alt="imgJejuLang"
          />
        </div>
      </div>
      <div className="inner380">
        <div className="inner">
          <img
            className="fullImg"
            src="/img/basic/jejuLangCon2.jpg"
            alt="imgJejuLang"
          />
        </div>
      </div>
      <Recommend
        tit={
          <>
            이 콘텐츠를 좋아하면,
            <br />
            이것도 좋아해요.
          </>
        }
        recoCard={[
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[7].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[7].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[7].h3}
            desc={CATEGORY_CONTENTS_LIST[7].p}
            page={CATEGORY_CONTENTS_LIST[7].page}
          />,
          <RecoCard
            img={CATEGORY_CONTENTS_LIST[3].img}
            keyword={[
              <KeywordList keyword={CATEGORY_CONTENTS_LIST[3].keyword} />,
            ]}
            conTit={CATEGORY_CONTENTS_LIST[3].h3}
            desc={CATEGORY_CONTENTS_LIST[3].p}
            page={CATEGORY_CONTENTS_LIST[3].page}
          />,
        ]}
      />
    </>
  );
}

export default Page3;
