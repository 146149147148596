import React from "react";
import Header from "../component/Header";
import Article1 from "../component/Article1";
import Keyword from "../component/Keyword";
import Card from "../component/Card";
import { CATEGORY_LIST, CATEGORY_CONTENTS_LIST } from "../data/data.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import KeywordList from "./../component/KeywordList";

function Place() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sort = searchParams.get("sort"); // sort 값을 가져온다. (url 쿼리스트링에서)

  const [filteredContents, setFilteredContents] = useState(
    CATEGORY_CONTENTS_LIST
  );
  const [subTitleObject, setSubTitleObject] = useState(CATEGORY_LIST[0]);

  useEffect(() => {
    const isAll = !sort || sort === "all";
    if (!CATEGORY_CONTENTS_LIST.length) return;
    if (isAll) {
      // sort의 값이 '전체'일 경우 전체 컨텐츠를 렌더링한다.
      setFilteredContents(CATEGORY_CONTENTS_LIST);
      return;
    }
    // sort의 값을 이용해 전체 컨텐츠에서 조건을 만족하는 새 컨텐츠로 구성된 배열을 만든다.
    const newContents = CATEGORY_CONTENTS_LIST.filter((c) => c.place === sort);
    setFilteredContents(newContents);

    //소제목 세팅
    setSubTitleObject(CATEGORY_LIST.find((item) => item.parameter === sort));
  }, [sort, CATEGORY_CONTENTS_LIST]);
  return (
    <>
      <Header />
      <Article1
        keyword={
          sort === "SEOGWIPO"
            ? ["NEWS", "HISTORY", "HOW-TO"].map((category) => (
                <Keyword keyword={category} ac={``} clickMenu={"Contents"} />
              ))
            : sort === "NOJI"
            ? ["PEOPLE", "HOW-TO", "BRAND", "ACTIVITY", "SPACE"].map(
                (category) => (
                  <Keyword keyword={category} ac={``} clickMenu={"Contents"} />
                )
              )
            : [
                "TRAVEL",
                "ACTIVITY",
                "HISTORY",
                "HOW-TO",
                "PEOPLE",
                "BRAND",
                "SPACE",
              ].map((category) => (
                <Keyword keyword={category} ac={``} clickMenu={"Contents"} />
              ))
        }
        h2={
          sort === "SEOGWIPO"
            ? `서귀포의 숨은 소식과`
            : sort === "NOJI"
            ? `노지 이야기를 즐기니`
            : `105개 마을에 두 발짝`
        }
        p={
          sort === "SEOGWIPO"
            ? `문화도시 서귀포 지식 충전하고 영감 수집하기`
            : sort === "NOJI"
            ? `서귀포에서 벌어지는 노지 라이프 실시간 중계`
            : `너도 나도 우리가 간다, 노지 마을 도장 깨는 법`
        }
        dis={"displayNone"}
        inner={`inner1220`}
      />

      <div className="cardView">
        <div className="inner">
          {filteredContents.map((d) => (
            <Card
              img={d.img}
              keyword={[<KeywordList keyword={d.keyword} />]}
              h3={d.h3}
              p={d.p}
              page={d.page}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Place;
