import React from "react";
function Desc({ name, text, boldYn }) {
  return (
    <p className="desc" style={{ fontWeight: boldYn ? "bold" : "normal" }}>
      {name}
      {text}
    </p>
  );
}
export default Desc;
